import "./CRUDButton.css";

function CRUDButton({ children, handleClick, deleteButton, tolTipContent }) {
  return (
    <button
      className={deleteButton ? "delete_btn" : ""}
      onClick={handleClick}
      data-tooltip-id="my-tooltip"
      data-tooltip-content={tolTipContent}
      data-tooltip-place="top"
    >
      {children}
    </button>
  );
}

export default CRUDButton;
