import { useEffect, useState } from "react";
import { ALL_EVENTS_API, EVENTS_VISIT_API } from "../../../../Utilities/APIs/APIs";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import Form from "../../../Partials/Layouts/Forms/Form";
import ImageInput from "../../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput";
import ShortTextInput from "../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";

function UpdateVisit({
  targetID,
  triggerFetch,
  setShowUpdateForm,
  setShowModal,
}) {
  const [name, setName] = useState("");
  const [locationLink, setLocationLink] = useState("");
  const [image, setImage] = useState("");
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetProductInfo() {
      const { data } = await axiosInstance.get(EVENTS_VISIT_API + "getSingleVIsit/" + targetID);
      setName(data.name);
      setImage(data.image);
      setLocationLink(data.locationLink);
      // console.log(data);
    }
    fetchAndSetProductInfo();
  }, [targetID, axiosInstance]);

  async function handleSubmit(e) {
    e.preventDefault();

    const itemData = new FormData();

    itemData.append("name", name);
    itemData.append("locationLink", locationLink);
    itemData.append("image", image);

    const response = await axiosInstance.patch(
      ALL_EVENTS_API + "visit/" + targetID,
      itemData
    );
    console.log(response);
    if (response) {
      setShowUpdateForm(false);
      setShowModal(false);
      triggerFetch();
    }
  }
  return (
    <Form onSubmit={handleSubmit} hasImage>
      <ShortTextInput
        label={`Name`}
        value={name}
        placeholder={`Enter Name`}
        setState={setName}
      />
      <ShortTextInput
        label={`Location Link`}
        value={locationLink}
        placeholder={`Enter Location Link`}
        setState={setLocationLink}
      />

      <ImageInput
        fieldId="1"
        state={image}
        setState={setImage}
        allowUpdateImage
      >
        Update Card Image
      </ImageInput>
      <FormSubmitButton text="Update Visit Image" />
    </Form>
  );
}

export default UpdateVisit;
