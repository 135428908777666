import { useState, useEffect } from "react";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import { APP_SETTINGS_API } from "../../../../Utilities/APIs/APIs";
import Image from "../../../Partials/Elements/Image/Image";

function ViewAppSetting({ targetID }) {
  const [content, setContent] = useState(null);

  const axiosInstance = useAxiosInstance();
  console.log(content, "from modal")

  useEffect(() => {
    async function fetchAndSetCategory() {
      const { data } = await axiosInstance.get(
        APP_SETTINGS_API + targetID
      );
      setContent(data);
    }
    fetchAndSetCategory();
  }, [axiosInstance, targetID]);

  

  return (
    <div className="crud_view_content">
      {content && (
        <>
          <h1>Title</h1>
          <p>{content?.title}</p>

          <h1>Image</h1>
          <Image imgLink={content.image} imgAlt={"Team Image"} />
        </>
      )}
    </div>
  );
}

export default ViewAppSetting;
