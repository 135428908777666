import { useEffect, useState } from "react";
import {
    EVENT_VISUALS_API,
} from "../../../../Utilities/APIs/APIs";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import Form from "../../../Partials/Layouts/Forms/Form";
import ImageInput from "../../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput";
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import Image from "../../../Partials/Elements/Image/Image";

function ViewVisual({
    setShowViewSection,
    setShowModal,
    triggerFetch,
    visualId,
}) {
    const [image, setImage] = useState("");
    const axiosInstance = useAxiosInstance();

    async function handleSubmit(e) {
        e.preventDefault();
        const itemData = new FormData();
        itemData.append("visualId", visualId);
        itemData.append("image", image);

        const response = await axiosInstance.post(
            EVENT_VISUALS_API + visualId,
            itemData
        );

        if (response.data) {
            setShowViewSection(false);
            setShowModal(false);
            triggerFetch();
        }
    }

    //   get visual data
    useEffect(() => {
        async function fetchData() {
            const res = await axiosInstance.get(EVENT_VISUALS_API + visualId)
            // console.log(res)
            setImage(res?.data?.[0]?.image)
        }

        fetchData()
    }, [])

    return (
        <div className="crud_view_content">
            {image && (
                <>
                    <Image imgLink={image} imgAlt={"Card Front Image"} />
                </>
            )}
        </div>
    );
}

export default ViewVisual;
