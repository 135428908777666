import { useEffect, useState } from "react";
import { APP_SETTINGS_API } from "../../../../Utilities/APIs/APIs";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import Form from "../../../Partials/Layouts/Forms/Form";
import ShortTextInput from "../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import ImageInput from "../../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput";

function UpdateAppSetting({
    targetID,
    triggerFetch,
    setShowUpdateForm,
    setShowModal,
}) {
    const [title, setTitle] = useState("");
    const [cardImage, setCardImage] = useState("");
    const axiosInstance = useAxiosInstance();

    useEffect(() => {
        async function fetchAndSetProductInfo() {
            const { data } = await axiosInstance.get(
                APP_SETTINGS_API + targetID
            );
            setTitle(data.title);
            setCardImage(data.image)
        }
        fetchAndSetProductInfo();
    }, [targetID, axiosInstance]);

    async function handleSubmit(e) {
        e.preventDefault();

        const itemData = new FormData();

        itemData.append("title", title);
        itemData.append("image", cardImage);

        const response = await axiosInstance.patch(
            APP_SETTINGS_API + "update/" + targetID,
            itemData
        );

        if (response) {
            setShowUpdateForm(false);
            setShowModal(false);
            triggerFetch();
        }
    }

    return (
        <Form onSubmit={handleSubmit} hasImage>
            <ShortTextInput
                label={`Title`}
                value={title}
                placeholder={`Enter Title`}
                setState={setTitle}
            />
            <ImageInput
                fieldId="1"
                state={cardImage}
                setState={setCardImage}
                allowUpdateImage
            >
                Upload Card Image
            </ImageInput>
            <FormSubmitButton text="Update Gallery Image" />
        </Form>
    );
}

export default UpdateAppSetting;
