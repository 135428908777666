import { useEffect, useState } from "react";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import { USEFUL_LINKS_API } from "../../../../Utilities/APIs/APIs";
import CreateButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDashboardBoilerplate/CRUDHeader/CreateButton/CreateButton";
import CRUDTable from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable";
import CRUDTableHeader from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow";
import ShortTextCell from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import CRUDButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/CRUDButton";
import { FiPenTool } from "react-icons/fi";
import Modal from "../../../Partials/Elements/Modal/Modal";
import CreateUsefulLink from "./components/CreateUsefulLinks";
import UpdateUsefulLink from "./components/UpdateUsefulLink";
import ArchiveItem from "../../../Partials/Layouts/ArchiveItem/ArchiveItem";
import ArchiveButton from "../../../Partials/Layouts/CRUDs/ArchiveButton/ArchiveButton";

function UsefulLinks() {
  const [content, setContent] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showArchiveSection, setShowArchiveSection] = useState(false);
  const [isArchive, setIsArchive] = useState(false);
  const [filter, setFilter] = useState("true");

  const [toggleFetch, setToggleFetch] = useState(false);

  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetContent() {
      const { data } = await axiosInstance.get(
        USEFUL_LINKS_API + `?filter=${filter}`
      );
      setContent(data);
    }
    fetchAndSetContent();
  }, [toggleFetch, axiosInstance, showModal, filter]);

  function handleClose() {
    setShowModal(false);
    setShowCreateForm(false);
    setShowUpdateForm(false);
  }

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }

  return (
    <>
      <div className="crud_holder">
        <div className="body_header">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "20px",
              width: "100%",
            }}
          >
            <h1 className="heading">{` Useful Links (${content?.length} in total)`}</h1>

            <div>
              <select
                onChange={(e) => setFilter(e.target.value)}
                value={filter}
                className="input_field"
              >
                <option value={"false"}>Archived</option>
                <option value={"true"}>Unarchived</option>
              </select>
            </div>

            <CreateButton
              screenTopicSingular={"Useful Link"}
              setShowCreateForm={setShowCreateForm}
              setShowModal={setShowModal}
            />
          </div>
        </div>
        <CRUDTable>
          <CRUDTableHeader>
            <CRUDth th="Name" />
            <CRUDth th="Link" />
            <CRUDth th="Actions" />
          </CRUDTableHeader>
          <tbody>
            {content?.map((item) => (
              <CRUDTableRow key={item._id}>
                <ShortTextCell text={item?.title} />
                <ShortTextCell text={item?.link} />

                <td className="action_button_cell">
                  <CRUDButton
                    tolTipContent={"Edit Useful Link"}
                    handleClick={() => {
                      setShowModal(true);
                      setShowUpdateForm(true);
                      setTargetID(item._id);
                    }}
                  >
                    <FiPenTool />
                  </CRUDButton>
                  <ArchiveButton
                    tolTipContent={"Archive Useful Link"}
                    setShowModal={setShowModal}
                    setShowDeleteSection={setShowArchiveSection}
                    targetID={item._id}
                    setTargetID={setTargetID}
                    setArchive={setIsArchive}
                    isArchive={item?.isActive}
                  />
                </td>
              </CRUDTableRow>
            ))}
          </tbody>
        </CRUDTable>
      </div>

      {showModal && (
        <Modal
          view
          handleClose={handleClose}
          modalHeading={showArchiveSection && `Archive This Useful Link`}
        >
          {showArchiveSection && (
            <ArchiveItem
              api={USEFUL_LINKS_API + "archive/"}
              targetID={targetID}
              isArchive={isArchive}
              setShowArchiveSection={setShowArchiveSection}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}
        </Modal>
      )}

      {showModal && showCreateForm && (
        <Modal
          view
          handleClose={handleClose}
          modalHeading={showCreateForm && `Create Useful Link`}
        >
          <CreateUsefulLink
            setShowCreateForm={setShowCreateForm}
            setShowModal={setShowModal}
            setFilter={setFilter}
            triggerFetch={triggerFetch}
          />
        </Modal>
      )}

      {showModal && showUpdateForm && (
        <Modal
          view
          handleClose={handleClose}
          modalHeading={showUpdateForm && `Update App Setting`}
        >
          <UpdateUsefulLink
            targetID={targetID}
            setShowUpdateForm={setShowUpdateForm}
            setShowModal={setShowModal}
            triggerFetch={triggerFetch}
          />
        </Modal>
      )}
    </>
  );
}

export default UsefulLinks;
