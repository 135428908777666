import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AppContext from "../../../../../../AppContext/AppContext";
import useAxiosInstance from "../../../../../../Utilities/Hooks/useAxiosInstance";
import { ALL_EVENTS_API } from "../../../../../../Utilities/APIs/APIs";
import CRUDTable from "../../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable";
import CRUDTableHeader from "../../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow";
import ShortTextCell from "../../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import ViewButton from "../../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton";
import CRUDButton from "../../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/CRUDButton";
import { HiBadgeCheck } from "react-icons/hi";
import Modal from "../../../../../Partials/Elements/Modal/Modal";
import APBCApproveItem from "../../../../APBC_Events/APBCTicket/APBCApproveItem";
import ViewAPBCTicket from "../../../../APBC_Events/APBCTicket/ViewAPBCTicket";

function EventTickets() {
  const { eventId } = useParams();
  const [tickets, setTickets] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [showApprove, setShowApprove] = useState(false);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);
  const [emailAddress, setEmailAddress] = useState(null);
  const { employee } = useContext(AppContext);

  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetTickets() {
      const { data } = await axiosInstance.get(
        ALL_EVENTS_API + "tickets/getAllTicketByEventId/" + eventId
      );
      setTickets(data);
    }
    fetchAndSetTickets();
  }, [toggleFetch, axiosInstance, eventId]);

  function handleClose() {
    setShowModal(false);
    setShowViewSection(false);
    setShowUpdateForm(false);
    setShowDeleteSection(false);
  }

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }

  return (
    <>
      <div className="crud_holder">
        <div className="body_header">
          <h1 className="heading">{`Event Ticket (${tickets?.length} in total)`}</h1>
        </div>
        <CRUDTable>
          <CRUDTableHeader>
            <CRUDth th="No" />
            <CRUDth th="Full name" />
            <CRUDth th="Email" />
            <CRUDth th="phoneNumber" />
            <CRUDth th="Status" />
            <CRUDth th="Action" />
          </CRUDTableHeader>
          <tbody>
            {tickets?.map((ticketItem, index) => (
              <CRUDTableRow key={ticketItem._id}>
                <ShortTextCell text={index + 1} />
                <ShortTextCell text={ticketItem?.fullName} />
                <ShortTextCell text={ticketItem?.emailAddress} />
                <ShortTextCell text={ticketItem?.phoneNumber} />
                <ShortTextCell text={ticketItem?.isApprove} />
                <div className="action_button_cell">
                  <ViewButton
                    tolTipContent={"View Event Item"}
                    setShowModal={setShowModal}
                    setShowViewSection={setShowViewSection}
                    targetID={ticketItem._id}
                    setTargetID={setTargetID}
                  />
                  {ticketItem.isApprove === "Pending" && (
                    <CRUDButton
                      tolTipContent={"Approve Ticket"}
                      handleClick={() => {
                        setShowModal(true);
                        setShowApprove(true);
                        setTargetID(ticketItem._id);
                        setEmailAddress(ticketItem.emailAddress);
                      }}
                    >
                      <HiBadgeCheck />
                    </CRUDButton>
                  )}

                  {/* <CRUDButton
                    handleClick={() => {
                      setShowModal(true);
                      setShowDeleteSection(true);
                      setTargetID(ticketItem._id);
                    }}
                  >
                    <TiTrash />
                  </CRUDButton> */}

                  {/* <DeleteButton
                    setShowModal={setShowModal}
                    setShowDeleteSection={setShowDeleteSection}
                    targetID={ticketItem._id}
                    setTargetID={setTargetID}
                  /> */}
                </div>
              </CRUDTableRow>
            ))}
          </tbody>
        </CRUDTable>
      </div>
      {showModal && (
        <Modal
          view
          handleClose={handleClose}
          modalHeading={
            (showUpdateForm && `Update Featured Item`) ||
            (showDeleteSection && `Delete Featured Item`)
          }
        >
          {showModal && showApprove && !showDeleteSection && (
            <APBCApproveItem
              api={ALL_EVENTS_API + "/tickets/"}
              targetID={targetID}
              employee={employee}
              setShowModal={setShowModal}
              setShowDeleteSection={setShowDeleteSection}
              hasName
              triggerFetch={triggerFetch}
              email={emailAddress}
            />
          )}

          {/* {showModal && showDeleteSection && (
            <DeleteItem
              api={ALL_EVENTS_API + "delete_ticket/"}
              targetID={targetID}
              employee={employee}
              setShowModal={setShowModal}
              setShowDeleteSection={setShowDeleteSection}
              hasName
              triggerFetch={triggerFetch}
            />
          )} */}
        </Modal>
      )}

      {showModal && showViewSection && (
        <Modal
          view
          handleClose={handleClose}
          modalHeading={showViewSection && `View Event Item`}
        >
          <ViewAPBCTicket targetID={targetID} />
        </Modal>
      )}
    </>
  );
}

export default EventTickets;
