import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AppContext from "../../../../AppContext/AppContext";
import { EVENT_VISUALS_API } from "../../../../Utilities/APIs/APIs";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import Modal from "../../../Partials/Elements/Modal/Modal";
import CreateButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDashboardBoilerplate/CRUDHeader/CreateButton/CreateButton";
import DeleteButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/DeleteButton/DeleteButton";
import CRUDTable from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable";
import ImageCell from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ImageCell/ImageCell";
import CRUDTableHeader from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow";
import DeleteItem from "../../../Partials/Layouts/DeleteItem/DeleteItem";
import CreateVisual from "./CreateVisual";
import ArchiveButton from "../../../Partials/Layouts/CRUDs/ArchiveButton/ArchiveButton";
import ArchiveItem from "../../../Partials/Layouts/ArchiveItem/ArchiveItem";
import FilterSelect from "../../../Partials/Layouts/Forms/FilterSelect/FilterSelect";
import ViewVisual from "./ViewVIsual";
import UpdateVisual from "./UpdateVisual";

function EventVisuals() {
  const { eventId } = useParams();

  const [showViewSection, setShowViewSection] = useState(false);
  const [images, setImages] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);

  const [filter, setFilter] = useState("active");

  const [archive, setArchive] = useState(false);

  const [showArchiveSection, setShowArchiveSection] = useState(false);
  const { employee } = useContext(AppContext);

  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetVisuals() {
      const { data } = await axiosInstance.get(
        EVENT_VISUALS_API + eventId + `?filter=${filter}`
      );
      setImages(data);
    }
    fetchAndSetVisuals();
  }, [axiosInstance, toggleFetch, eventId, filter]);

  function handleClose() {
    setShowViewSection(false);
    setShowCreateForm(false);
    setShowUpdateForm(false);
    setShowDeleteSection(false);
    setShowArchiveSection(false);
    setShowModal(false);
  }

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }

  return (
    <>
      <div className="crud_holder">
        <div className="body_header">
          <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
            <h1 className="heading">
              Event Visuals ({images?.length} in total)
            </h1>

            <FilterSelect filter={filter} setFilter={setFilter} />
          </div>

          <CreateButton
            screenTopicSingular={"Create Event Visual"}
            setShowCreateForm={setShowCreateForm}
            setShowModal={setShowModal}
          />
        </div>
        <CRUDTable>
          <CRUDTableHeader>
            <CRUDth th="Images" />
            <CRUDth th="Actions" />
          </CRUDTableHeader>
          <tbody>
            {images &&
              images?.map((image) => (
                <CRUDTableRow key={image._id}>
                  <ImageCell
                    imgSrc={process.env.REACT_APP_SPACES_URL + image?.image}
                    alt={"team member"}
                  />

                  <td className="action_button_cell">
                    {/* <ViewButton
                      setShowModal={setShowModal}
                      setShowViewSection={setShowViewSection}
                      targetID={image._id}
                      setTargetID={setTargetID}
                    /> */}
                    {/* <EditButton
                      setShowModal={setShowModal}
                      setShowUpdateForm={setShowUpdateForm}
                      targetID={image._id}
                      setTargetID={setTargetID}
                    /> */}
                    <ArchiveButton
                      setShowModal={setShowModal}
                      setShowDeleteSection={setShowArchiveSection}
                      targetID={image._id}
                      isArchive={image?.isActive}
                      setTargetID={setTargetID}
                      setArchive={setArchive}
                    />
                    <DeleteButton
                      tolTipContent={"Delete Visual"}
                      setShowModal={setShowModal}
                      setShowDeleteSection={setShowDeleteSection}
                      targetID={image._id}
                      setTargetID={setTargetID}
                    />
                  </td>
                </CRUDTableRow>
              ))}
          </tbody>
        </CRUDTable>
      </div>

      {showModal && (
        <Modal
          handleClose={handleClose}
          modalHeading={
            (showCreateForm && `Create Visual`) ||
            (showUpdateForm && `Update Street `) ||
            (showDeleteSection && `Delete Street`) ||
            (showViewSection && `View Event Visuals`)
          }
        >
          {showCreateForm && (
            <CreateVisual
              employee={employee}
              eventId={eventId}
              setShowCreateForm={setShowCreateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}
          {showViewSection && (
            <ViewVisual
              employee={employee}
              visualId={eventId}
              setShowCreateForm={setShowCreateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}
          {showUpdateForm && (
            <UpdateVisual
              employee={employee}
              visualId={eventId}
              setShowUpdateForm={setShowUpdateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}

          {showDeleteSection && (
            <DeleteItem
              api={EVENT_VISUALS_API}
              targetID={targetID}
              employee={employee}
              setShowModal={setShowModal}
              setShowDeleteSection={setShowDeleteSection}
              triggerFetch={triggerFetch}
            />
          )}

          {showArchiveSection && (
            <ArchiveItem
              api={EVENT_VISUALS_API + "archive/"}
              targetID={targetID}
              employee={employee}
              setShowModal={setShowModal}
              setShowArchiveSection={setShowArchiveSection}
              hasName
              triggerFetch={triggerFetch}
              isArchive={archive}
            />
          )}
        </Modal>
      )}
    </>
  );
}

export default EventVisuals;
