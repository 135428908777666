import { useContext, useEffect, useState } from "react";
import {
  IMAGE_URL,
  MANAGE_EMPLOYEE_API,
} from "../../../../Utilities/APIs/APIs";
import CRUDTable from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable";
import ImageCell from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ImageCell/ImageCell";
import ShortTextCell from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import CRUDTableHeader from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow";
import AppContext from "../../../../AppContext/AppContext";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import ViewEmployee from "./ViewEmployee";
import ViewButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton";
import Modal from "../../../Partials/Elements/Modal/Modal";
import DeleteItem from "../../../Partials/Layouts/DeleteItem/DeleteItem";
import DeleteButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/DeleteButton/DeleteButton";

function Employees() {
  const [employees, setEmployees] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);
  const { employee } = useContext(AppContext);
  const axiosInstance = useAxiosInstance(AppContext);

  useEffect(() => {
    async function fetchAndSetEmployees() {
      const { data } = await axiosInstance.get(
        MANAGE_EMPLOYEE_API + "getAllEmployees"
      );
      setEmployees(data);
    }
    fetchAndSetEmployees();
  }, [toggleFetch, axiosInstance]);

  function handleClose() {
    setShowModal(false);
    setShowViewSection(false);
  }

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }

  return (
    <>
      <div className="crud_holder">
        <div className="body_header">
          <h1 className="heading">{`Admins (${employees?.length} in total)`}</h1>
        </div>
        <CRUDTable>
          <CRUDTableHeader>
            <CRUDth th="Admin name" />
            <CRUDth th="Image" />
            <CRUDth th="Email" />
            <CRUDth th="Actions" />
          </CRUDTableHeader>
          <tbody>
            {employees?.map((emp) => (
              <CRUDTableRow key={emp?._id}>
                <ShortTextCell text={emp?.name} />
                <ImageCell imgSrc={IMAGE_URL + emp?.dp} alt={emp?.name} />
                <ShortTextCell text={emp?.email} />
                <td className="action_button_cell">
                  <ViewButton
                    tolTipContent={"View"}
                    setShowModal={setShowModal}
                    setShowViewSection={setShowViewSection}
                    targetID={emp?._id}
                    setTargetID={setTargetID}
                  />
                  <DeleteButton
                    tolTipContent={"Delete"}
                    setShowModal={setShowModal}
                    setShowDeleteSection={setShowDeleteSection}
                    targetID={emp?._id}
                    setTargetID={setTargetID}
                  />
                </td>
              </CRUDTableRow>
            ))}
          </tbody>
        </CRUDTable>
      </div>
      {showModal && (
        <Modal
          handleClose={handleClose}
          modalHeading={showViewSection && `View Employ Details`}
        >
          {showDeleteSection && (
            <DeleteItem
              api={MANAGE_EMPLOYEE_API}
              targetID={targetID}
              employee={employee}
              setShowModal={setShowModal}
              setShowDeleteSection={setShowDeleteSection}
              hasName
              triggerFetch={triggerFetch}
            />
          )}
          {showViewSection && (
            <ViewEmployee targetID={targetID} employee={employee} />
          )}
        </Modal>
      )}
    </>
  );
}

export default Employees;
