import { useContext, useEffect, useState } from "react";
import AppContext from "../../../../AppContext/AppContext";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import { HOME_API } from "../../../../Utilities/APIs/APIs";
import CreateButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDashboardBoilerplate/CRUDHeader/CreateButton/CreateButton";
import CRUDTable from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable";
import CRUDTableHeader from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow";
import ShortTextCell from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import EditButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/EditButton/EditButton";
import DeleteButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/DeleteButton/DeleteButton";
import Modal from "../../../Partials/Elements/Modal/Modal";
import DeleteItem from "../../../Partials/Layouts/DeleteItem/DeleteItem";
import ImageCell from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ImageCell/ImageCell";
import ViewButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton";
import CreateBenefitForm from "../HomeForms/CreateBenefitForm";
import ViewBenefitDetail from "../HomeForms/ViewBenefitDetail";
import UpdateBenefitsForm from "../HomeForms/UpdateBenefitsForm";

function TeamSection() {
  const [Benefits, setBenefits] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectItem, setSelectItem] = useState("Micro surgical workshop");
  const [targetID, setTargetID] = useState(null);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);
  const { employee } = useContext(AppContext);

  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetBenefits() {
      const { data } = await axiosInstance.get(HOME_API + "benefits");

      console.log(data);
      setBenefits(data);
    }
    fetchAndSetBenefits();
  }, [toggleFetch, axiosInstance, showModal]);

  function handleClose() {
    setShowModal(false);
    setShowCreateForm(false);
    setShowViewSection(false);
    setShowUpdateForm(false);
    setShowDeleteSection(false);
  }

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }

  return (
    <>
      <div className="crud_holder">
        <div className="body_header">
          <h1 className="heading">{`Benefits (${Benefits?.length} in total)`}</h1>
          <CreateButton
            screenTopicSingular="Benefits"
            setShowModal={setShowModal}
            setShowCreateForm={setShowCreateForm}
          />
        </div>
        <CRUDTable>
          <CRUDTableHeader>
            <CRUDth th="title" />
            {/* <CRUDth th="Description" /> */}
            <CRUDth th="icon" />
            {/* <CRUDth th="Benefit List" /> */}
            <CRUDth th="Actions" />
          </CRUDTableHeader>
          <tbody>
            {Benefits?.map((benefit) => (
              <CRUDTableRow key={benefit._id}>
                <ShortTextCell text={benefit?.title} />
                {/* <ShortTextCell text={benefit?.description} /> */}
                <ImageCell
                  imgSrc={process.env.REACT_APP_SPACES_URL + benefit.icon}
                  alt={"benefit member"}
                />
                {/* <SelectInput value={selectItem} setState={setSelectItem}>
                  <SelectOption optionValue={0} />

                  {benefit?.benefitsLists?.map((item, index) => (
                    <SelectOption
                      key={index}
                      optionValue={item}
                      optionText={item}
                    />
                  ))}
                </SelectInput> */}

                <td className="action_button_cell">
                  <ViewButton
                    tolTipContent={"View Benefits"}
                    setShowModal={setShowModal}
                    setShowViewSection={setShowViewSection}
                    targetID={benefit._id}
                    setTargetID={setTargetID}
                  />
                  <EditButton
                    tolTipContent={"Edit Benefits"}
                    setShowModal={setShowModal}
                    setShowUpdateForm={setShowUpdateForm}
                    targetID={benefit._id}
                    setTargetID={setTargetID}
                  />
                  <DeleteButton
                    tolTipContent={"Delete Benefits"}
                    setShowModal={setShowModal}
                    setShowDeleteSection={setShowDeleteSection}
                    targetID={benefit._id}
                    setTargetID={setTargetID}
                  />
                </td>
              </CRUDTableRow>
            ))}
          </tbody>
        </CRUDTable>
      </div>
      {showModal && (
        <Modal
          view
          handleClose={handleClose}
          modalHeading={
            (showCreateForm && `Create Benefits Item`) ||
            (showUpdateForm && `Update Benefits Item`) ||
            (showDeleteSection && `Delete Benefits Item`)
          }
        >
          {showCreateForm && (
            <CreateBenefitForm
              employee={employee}
              setShowCreateForm={setShowCreateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}
          {showUpdateForm && (
            <UpdateBenefitsForm
              targetID={targetID}
              employee={employee}
              setShowUpdateForm={setShowUpdateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}

          {showDeleteSection && (
            <DeleteItem
              api={HOME_API + "Benefits/"}
              targetID={targetID}
              employee={employee}
              setShowModal={setShowModal}
              setShowDeleteSection={setShowDeleteSection}
              hasName
              triggerFetch={triggerFetch}
            />
          )}
        </Modal>
      )}

      {showModal && showViewSection && (
        <Modal
          view
          handleClose={handleClose}
          modalHeading={showViewSection && `View Benefits Member`}
        >
          <ViewBenefitDetail targetID={targetID} />
        </Modal>
      )}
    </>
  );
}

export default TeamSection;
