import { RiMapPin2Fill } from "react-icons/ri";

import NavCardList from "../../Partials/Layouts/NavCardList/NavCardList";
import NavCard from "../../Partials/Layouts/NavCardList/NavCard/NavCard";
import { useContext, useState } from "react";
import UpdateItem from "../ItemScreens/Items/ItemCRUDS/UpdateItem";
import AppContext from "../../../AppContext/AppContext";
import Modal from "../../Partials/Elements/Modal/Modal";
import UpdateConferenceAndMembershipForm from "./HomeForms/UpdateConferenceAndMembershipForm";
import UpdateYoutubeLink from "./YoutubeLink/YoutubeLink";

function HomeContent() {
  const [showYoutubeLinkForm, setShowYoutubeLinkForm] = useState(false);
  const [showConferenceAndMembershipForm, setShowConferenceAndMembershipForm] =
    useState(false);
  const [showModal, setShowModal] = useState(false);
  const { employee } = useContext(AppContext);

  const closeModal = () => {
    setShowConferenceAndMembershipForm(false);
    setShowModal(false);
  };
  return (
    <>
      <NavCardList numOfCards={"eight"}>
        <NavCard cardName={"Hero"} navCardLink="/hero">
          <RiMapPin2Fill />
        </NavCard>

        <NavCard cardName={"Our Team"} navCardLink="/team">
          <RiMapPin2Fill />
        </NavCard>
        <NavCard cardName={"Statistics"} navCardLink="/revenueGrowth">
          <RiMapPin2Fill />
        </NavCard>

        <NavCard
          cardName={"Conference And Membership"}
          navCardLink="#"
          onClick={() => {
            setShowConferenceAndMembershipForm(true);
            setShowModal(true);
          }}
        >
          <RiMapPin2Fill />
        </NavCard>

        <NavCard cardName={"Benefits"} navCardLink="/benefits">
          <RiMapPin2Fill />
        </NavCard>

        <NavCard
          cardName={"Government Initiative"}
          navCardLink="/governmentInitiative"
        >
          <RiMapPin2Fill />
        </NavCard>

        <NavCard
          cardName={"Youtube Link"}
          navCardLink="#"
          onClick={() => {
            setShowYoutubeLinkForm(true);
            setShowModal(true);
          }}
        >
          <RiMapPin2Fill />
        </NavCard>
      </NavCardList>
      {showModal && (
        <Modal
          handleClose={closeModal}
          modalHeading={
            (showConferenceAndMembershipForm &&
              "Update Conference And Membership Items") ||
            (showYoutubeLinkForm && "Youtube Link")
          }
        >
          {showConferenceAndMembershipForm && (
            <UpdateConferenceAndMembershipForm
              employee={employee}
              targetID={"667ab4020b638766f891ce43"}
              setShowUpdateForm={setShowConferenceAndMembershipForm}
              setShowModal={setShowModal}
              // triggerFetch={triggerFetch}
            />
          )}
          {showYoutubeLinkForm && (
            <UpdateYoutubeLink
              employee={employee}
              targetID={"667ab6d70b638766f891ce90"}
              setShowUpdateForm={setShowConferenceAndMembershipForm}
              setShowModal={setShowModal}
              // triggerFetch={triggerFetch}
            />
          )}
        </Modal>
      )}
    </>
  );
}

export default HomeContent;
