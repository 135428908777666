import { useState } from "react";
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance";
import Form from "../../../../Partials/Layouts/Forms/Form";
import ShortTextInput from "../../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import ImageInput from "../../../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput";
import FormSubmitButton from "../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import { APP_SETTINGS_API } from "../../../../../Utilities/APIs/APIs";


function CreateAppSetting({
    setShowCreateForm,
    setShowModal,
    triggerFetch,
}) {
    const [image, setImage] = useState("");
    const [title, setTitle] = useState(0);
    const axiosInstance = useAxiosInstance();

    async function handleSubmit(e) {
        e.preventDefault();
        const itemData = new FormData();
        itemData.append("title", title);
        itemData.append("image", image);

        console.log(image);
        const response = await axiosInstance.post(
            APP_SETTINGS_API,
            itemData
        );

        console.log(response);
        if (response.data) {
            setShowCreateForm(false);
            setShowModal(false);
            triggerFetch();
        }
    }

    return (
        <Form onSubmit={handleSubmit} hasImage>
            <ShortTextInput
                fieldId="2"
                state={title}
                setState={setTitle}
                placeholder="title"
            />
            <ImageInput
                fieldId="1"
                state={image}
                setState={setImage}
                allowCreateImage
            >
                Upload Gallery Image
            </ImageInput>
            <FormSubmitButton text="Create Gallery Image" />
        </Form>
    );
}

export default CreateAppSetting;
