import { useContext, useEffect, useState } from "react"
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance"
import AppContext from "../../../../AppContext/AppContext"
import { SOCIAL_LINKS_API } from "../../../../Utilities/APIs/APIs"
import CreateButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDashboardBoilerplate/CRUDHeader/CreateButton/CreateButton"
import CRUDTable from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable"
import CRUDTableHeader from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader"
import CRUDth from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth"
import CRUDTableRow from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow"
import ShortTextCell from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell"
import EditButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/EditButton/EditButton"
import DeleteButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/DeleteButton/DeleteButton"
import Modal from "../../../Partials/Elements/Modal/Modal"
import DeleteItem from "../../../Partials/Layouts/DeleteItem/DeleteItem"
import CreateSocialLinks from "./CreateSocialLinks"
import ViewSocialLinks from "./ViewSocialLinks"
import ViewButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton"
import UpdateSocialLinks from "./UpdateSocialLinks"





function SocialLinks() {

  const [socialLinks, setSocialLinks] = useState(null)

  const [showModal, setShowModal] = useState(false)
  const [targetID, setTargetID] = useState(null)
  const [showViewSection, setShowViewSection] = useState(false)
  const [showCreateForm, setShowCreateForm] = useState(false)
  const [showUpdateForm, setShowUpdateForm] = useState(false)
  const [showDeleteSection, setShowDeleteSection] = useState(false)
  const [toggleFetch, setToggleFetch] = useState(false)
  const { employee } = useContext(AppContext)

  const axiosInstance = useAxiosInstance()

  useEffect(() => {

    async function fetchAndSetSocialLinks() {
      const { data } = await axiosInstance.get(SOCIAL_LINKS_API)
      setSocialLinks(data)
    }
    fetchAndSetSocialLinks()


  }, [toggleFetch, axiosInstance])

  function handleClose() {
    setShowModal(false);
    setShowCreateForm(false);
    setShowViewSection(false);
    setShowUpdateForm(false);
    setShowDeleteSection(false);
  }

  function triggerFetch() {
    setToggleFetch(prevState => !prevState)
  }


  return (
    <>
      <div className='crud_holder'>
        <div className="body_header">
          <h1 className="heading">{`Social Links (${socialLinks?.length} in total)`}</h1>
          <CreateButton
            screenTopicSingular='Social Links'
            setShowModal={setShowModal}
            setShowCreateForm={setShowCreateForm}
          />
        </div>
        <CRUDTable>
          <CRUDTableHeader>
            <CRUDth th='Name' />
            <CRUDth th='Link' />
            <CRUDth th='Actions' />
          </CRUDTableHeader>
          <tbody>
            {socialLinks?.map(vat => (
              <CRUDTableRow key={vat._id}>
                <ShortTextCell text={vat?.name} />
                <ShortTextCell text={vat?.link} />

                <td className="action_button_cell">
                  <ViewButton
                  tolTipContent={"View Social Links"}
                    setShowModal={setShowModal}
                    setShowViewSection={setShowViewSection}
                    targetID={vat._id}
                    setTargetID={setTargetID}
                                  />
                  <EditButton
                  tolTipContent={"Edit Social Links"}
                    setShowModal={setShowModal}
                    setShowUpdateForm={setShowUpdateForm}
                    targetID={vat._id}
                    setTargetID={setTargetID}
                  />
                  <DeleteButton
                    setShowModal={setShowModal}
                    setShowDeleteSection={setShowDeleteSection}
                    targetID={vat._id}
                    setTargetID={setTargetID}
                  />

                </td>
              </CRUDTableRow>
            ))}
          </tbody>
        </CRUDTable>
      </div>
      {showModal &&
        <Modal
          view
          handleClose={handleClose}
          modalHeading={
            (showCreateForm && `Create Social Link`) ||
            (showUpdateForm && `Update Social Link`) ||
            (showDeleteSection && `Delete Social Link`)
          }
        >
          {
            showCreateForm &&
            <CreateSocialLinks
              employee={employee}
              setShowCreateForm={setShowCreateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          }
          {
            showUpdateForm &&
            <UpdateSocialLinks
              employee={employee}
              targetID={targetID}
              setShowUpdateForm={setShowUpdateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          }


          {
            showDeleteSection &&
            <DeleteItem
              api={SOCIAL_LINKS_API}
              targetID={targetID}
              employee={employee}
              setShowModal={setShowModal}
              setShowDeleteSection={setShowDeleteSection}
              hasName
              triggerFetch={triggerFetch}
            />
          }
        </Modal>
      }
      {showModal && showViewSection &&
              <Modal
                  view
                  handleClose={handleClose}
                  modalHeading={
                    (showViewSection && `View Social Links`)
                  }
              >
                <ViewSocialLinks
                      targetID={targetID}
                      employee={employee}
                  />
              </Modal>
          }
    </>
  )
}

export default SocialLinks
