import { useEffect, useState } from "react";
import {
    EVENT_VISUALS_API,
} from "../../../../Utilities/APIs/APIs";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import Form from "../../../Partials/Layouts/Forms/Form";
import ImageInput from "../../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput";
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";

function UpdateVisual({
    visualId,
    triggerFetch,
    setShowUpdateForm,
    setShowModal,
}) {
    const [image, setImage] = useState("");
    const axiosInstance = useAxiosInstance();

    useEffect(() => {
        async function fetchAndSetProductInfo() {
            const { data } = await axiosInstance.get(
                EVENT_VISUALS_API + visualId
            );
            setImage(data[0]?.image);
        }
        fetchAndSetProductInfo();
    }, [visualId, axiosInstance]);

    async function handleSubmit(e) {
        e.preventDefault();

        const itemData = new FormData();

        itemData.append("image", image);

        const response = await axiosInstance.patch(
            EVENT_VISUALS_API + visualId,
            itemData
        );

        if (response) {
            setShowUpdateForm(false);
            setShowModal(false);
            triggerFetch();
        }
    }

    console.log(visualId)

    return (
        <Form onSubmit={handleSubmit} hasImage>
            <ImageInput
                fieldId="1"
                state={image}
                setState={setImage}
                allowUpdateImage
            >
                Update Visual Image
            </ImageInput>
            <FormSubmitButton text="Update Visual Image" />
        </Form>
    );
}

export default UpdateVisual;
