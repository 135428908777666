import React from "react";
import "./DownloadData.css"
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
export default function DownloadDataModal({ setDownload }) {
  const axiosInstance = useAxiosInstance()


  async function downloadData() {
    const {data} = await axiosInstance.get('websiteContents/downloadData')
    console.log(data)
  }

  return (
    <div className="container">
      <p className="">Are you sure you want to download data?</p>
      <div className="btn-group">
        <button onClick={() => setDownload(false)}>Cancel</button>
        <button onClick={downloadData}>Download</button>
        </div>
    </div>
  );
}
