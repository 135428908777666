// import { backendUrl, spacesBucketUrl } from "../Constants"

// export const BASE_URL = backendUrl

// export const IMAGE_URL = spacesBucketUrl
// export const API_URL = BASE_URL + 'api/'

export const BASE_URL = process.env.REACT_APP_BACKEND_URL;
export const IMAGE_URL = process.env.REACT_APP_SPACES_URL;

export const API_URL = BASE_URL + "api/";

export const LOCATION_API = "http://backend.anthill.club/api/";

export const EMPLOYEES_API = API_URL + "employees/";
// export const CUSTOMERS_API = API_URL + 'customers/'

export const ADDRESSES_API = API_URL + "addresses/";

// export const ORDERS_API = API_URL + 'orders/'

// export const ITEMS_API = API_URL + 'items/'
// export const ITEM_ASSETS_API = API_URL + 'itemAssets/'
// export const FEATURED_ITEMS_API = API_URL + 'featuredItems/'
export const WISHLIST_API = API_URL + "wishlists/";

// export const ITEM_COLLECTIONS_API = API_URL + 'itemCollections/'
// export const ITEM_CATEGORIES_API = API_URL + 'itemCategories/'
// export const ITEM_SUBCATEGORIES_API = API_URL + 'itemSubcategories/'

// export const CATEGORIES_API = API_URL + 'categories/'
// export const FEATURED_CATEGORIES_API = API_URL + 'featuredCategories/'
// export const SUBCATEGORIES_API = API_URL + 'subCategories/'

// export const COLLECTIONS_API = API_URL + 'collections/'
// export const NAV_COLLECTIONS_API = API_URL + 'navCollections/'

export const SIZE_GROUPS_API = API_URL + "sizeGroups/";
export const SIZES_API = API_URL + "sizes/";
export const ITEM_SIZES_API = API_URL + "itemSizes/";

export const AREAS_API = LOCATION_API + "areas/";
export const DISTRICTS_API = LOCATION_API + "districts/";
export const DIVISIONS_API = LOCATION_API + "divisions/";

// export const VATS_API = API_URL + 'vats/'
// export const DISCOUNTS_API = API_URL + 'discounts/'
// export const DELIVERY_FEES_API = API_URL + 'deliveryFees/'

// export const SOCIAL_LINKS_API = API_URL + 'socialLinks/'
// export const HERO_SLIDERS_API = API_URL + 'heroSliders/'
// export const TESTIMONIALS_API = API_URL + 'testimonials/'
// export const APP_SETTINGS_API = API_URL + 'appSettings/'

// Utilities/APIs = Utilities/APIs/APIs

// -----------------------bhog-admin-api---------------

export const MANAGE_EMPLOYEE_API = "manageEmployees/";

// website Contents  -------------
export const WEBSITE_CONTENT_API = "websiteContents/";
export const HOME_API = WEBSITE_CONTENT_API + "home/";
export const about_API = WEBSITE_CONTENT_API + "about/";
export const CONTACT = WEBSITE_CONTENT_API + "contacts/";
export const BLOGS = WEBSITE_CONTENT_API + "blogs/";
export const GALLERY_API = WEBSITE_CONTENT_API + "gallery/";
export const GALLERY_IMAGE_API =
  WEBSITE_CONTENT_API + "gallery/galleryImage/getAllImgaesByGalleryId/";
export const EVENTS_API = WEBSITE_CONTENT_API + "events/";

export const EVENT_VISUALS_API = WEBSITE_CONTENT_API + "allEvents/visual/";

export const ALL_EVENTS_API = WEBSITE_CONTENT_API + "allEvents/";
export const EVENTS_VISIT_API = ALL_EVENTS_API + "visit/";

export const APBC_Events_API = WEBSITE_CONTENT_API + "APBC_Events/";
export const APBC_Events_Ticket_API = APBC_Events_API + "ticket/";
export const APBC_GALLERY_IMAGE_API = WEBSITE_CONTENT_API + "APBC_Gallery/";
export const EVENTS_HOTEL_API =
  WEBSITE_CONTENT_API + "events/nearbyhotels/getAllNearByHotelByEventId/";
export const APBC_VISIT_API = APBC_Events_API + "/visit/getAllVisitByEventId/";
export const TICKET_API = WEBSITE_CONTENT_API + "events/ticket";

export const SOCIAL_LINKS_API = WEBSITE_CONTENT_API + "socialLinks/";
export const HERO_SLIDERS_API = WEBSITE_CONTENT_API + "heroSliders/";
export const TESTIMONIALS_API = WEBSITE_CONTENT_API + "testimonials/";

export const USEFUL_LINKS_API = WEBSITE_CONTENT_API + "usefulLinks/";

// export const APP_SETTINGS_API = WEBSITE_CONTENT_API + "appSettings/";
export const APP_SETTINGS_API = WEBSITE_CONTENT_API + "websiteInfo/";
export const Payment_detail_API = WEBSITE_CONTENT_API + "payment_detail/";

export const AMANAGE_APP_SETTING_API = APP_SETTINGS_API + "manageAppSettings/";
export const DELIVERY_FEES_API = APP_SETTINGS_API + "deliveryFees/";
export const DISCOUNTS_API = APP_SETTINGS_API + "discounts/";
export const VATS_API = APP_SETTINGS_API + "vats/";

export const WEBSITE_INFO = WEBSITE_CONTENT_API + "websiteInfo/";
export const DELETE_WEBSITE_INFO = WEBSITE_INFO + "delete/";

// -------items---------
export const ITEMS_API = "items/";

// ---------control-Items------
export const CONTROL_ITEMS_API = ITEMS_API + "controlItems/";

export const MANAGE_ITEM_API = CONTROL_ITEMS_API + "manageItem/";
export const ITEM_ASSETS_API = CONTROL_ITEMS_API + "itemAssets/";
export const FEATURED_ITEMS_API = CONTROL_ITEMS_API + "featuredItems/";

// ---------Item-Groups------
export const ITEMS_GROUP_API = ITEMS_API + "itemGroups/";

// ---------Categories------
export const CATEGORIES_API = ITEMS_GROUP_API + "categories/";

export const MANAGE_CATEGORY_API = CATEGORIES_API + "manageCategories/";
export const SUBCATEGORIES_API = CATEGORIES_API + "subCategories/";
export const FEATURED_CATEGORIES_API = CATEGORIES_API + "featuredCategories/";
export const ITEM_CATEGORIES_API = CATEGORIES_API + "itemCategories/";
export const ITEM_SUBCATEGORIES_API = CATEGORIES_API + "itemSubcategories/";
// ---------Collections------
export const COLLECTIONS_API = ITEMS_GROUP_API + "collections/";

export const MANAGE_COLLECTIONS_API = COLLECTIONS_API + "manageCollections/";
export const NAV_COLLECTIONS_API = COLLECTIONS_API + "navCollections/";
export const ITEM_COLLECTIONS_API = COLLECTIONS_API + "itemCollections/";

// -------customers---------

export const CUSTOMERS_API = "customers/";

export const MANAGE_CUSTOMER = CUSTOMERS_API + "manageCustomers/";

export const ORDERS_API = CUSTOMERS_API + "orders/";
export const CHANGE_ORDERS_STATUS_API = ORDERS_API + "changeOrderStatus/";

/*
<BLNCRUD>
*/
