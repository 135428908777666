import { useContext, useEffect, useState } from "react";
import { IoTicketOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { FaHotel } from "react-icons/fa";
import { MdOutlineTour } from "react-icons/md";

import CreateButton from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDashboardBoilerplate/CRUDHeader/CreateButton/CreateButton";
import CRUDTable from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable";
import CRUDTableHeader from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow";
import ShortTextCell from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import ImageCell from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ImageCell/ImageCell";
import ViewButton from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton";
import EditButton from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/EditButton/EditButton";
import CRUDButton from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/CRUDButton";
import DeleteButton from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/DeleteButton/DeleteButton";
import DeleteItem from "../../../../Partials/Layouts/DeleteItem/DeleteItem";

import ViewEvent from "./components/ViewEvent";
import CreateEvent from "./components/CreateEvents";
import UpdatePremiumEvent from "./components/updatePremiumEvents";

import { ALL_EVENTS_API } from "../../../../../Utilities/APIs/APIs";
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance";
import AppContext from "../../../../../AppContext/AppContext";
import Modal from "../../../../Partials/Elements/Modal/Modal";

function PremiumEvents() {
  const [eventItems, setEventItems] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [showApprove, setShowApprove] = useState(false);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);
  const { employee } = useContext(AppContext);

  const axiosInstance = useAxiosInstance();
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchAndSetEvents() {
      const { data } = await axiosInstance.get(
        ALL_EVENTS_API + "?category=premium"
      );
      console.log(data);
      setEventItems(data);
    }
    fetchAndSetEvents();
  }, [toggleFetch, axiosInstance]);

  function handleClose() {
    setShowModal(false);
    setShowCreateForm(false);
    setShowViewSection(false);
    setShowUpdateForm(false);
    setShowDeleteSection(false);
  }

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }

  return (
    <>
      <div className="crud_holder">
        <div className="body_header">
          <h1 className="heading">{`Premium Events (${eventItems?.length} in total)`}</h1>
          <CreateButton
            screenTopicSingular="Premium Events"
            setShowModal={setShowModal}
            setShowCreateForm={setShowCreateForm}
          />
        </div>
        <CRUDTable>
          <CRUDTableHeader>
            <CRUDth th="No" />
            <CRUDth th="Title" />
            <CRUDth th="Image" />
            <CRUDth th="Action" />
          </CRUDTableHeader>
          <tbody>
            {eventItems?.map((eventItem, index) => (
              <CRUDTableRow key={eventItem._id}>
                <ShortTextCell text={index + 1} />
                <ShortTextCell text={eventItem?.title} />
                <ImageCell
                  imgSrc={
                    process.env.REACT_APP_SPACES_URL + eventItem?.imageUrl
                  }
                  alt={"team member"}
                />

                <td className="action_button_cell">
                  <ViewButton
                    tolTipContent={"View Event"}
                    setShowModal={setShowModal}
                    setShowViewSection={setShowViewSection}
                    targetID={eventItem._id}
                    setTargetID={setTargetID}
                  />
                  <EditButton
                    tolTipContent={"Edit Event"}
                    setShowModal={setShowModal}
                    setShowUpdateForm={setShowUpdateForm}
                    targetID={eventItem._id}
                    setTargetID={setTargetID}
                  />

                  <CRUDButton
                    tolTipContent={"Hotels"}
                    handleClick={() => {
                      navigate(`/hotel_content/${eventItem._id}`);
                    }}
                  >
                    <FaHotel />
                  </CRUDButton>

                  <CRUDButton
                    tolTipContent={"Visit"}
                    handleClick={() => {
                      navigate(`/visit_content/${eventItem._id}`);
                    }}
                  >
                    <MdOutlineTour />
                  </CRUDButton>
                  <CRUDButton
                    tolTipContent={"Tickets"}
                    handleClick={() => {
                      navigate(`/allEvents/ticket/${eventItem._id}`);
                      setShowModal(true);
                      setShowApprove(true);
                      setTargetID(eventItem._id);
                    }}
                  >
                    <IoTicketOutline />
                  </CRUDButton>

                  <DeleteButton
                    tolTipContent={"Delete Event"}
                    setShowModal={setShowModal}
                    setShowDeleteSection={setShowDeleteSection}
                    targetID={eventItem._id}
                    setTargetID={setTargetID}
                  />
                </td>
              </CRUDTableRow>
            ))}
          </tbody>
        </CRUDTable>
      </div>
      {showModal && (
        <Modal
          view
          handleClose={handleClose}
          modalHeading={
            (showCreateForm && `Create Event Item`) ||
            (showUpdateForm && `Update Event Item`) ||
            (showDeleteSection && `Delete Event Item`)
          }
        >
          {showCreateForm && (
            <CreateEvent
              employee={employee}
              setShowCreateForm={setShowCreateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}
          {showUpdateForm && (
            <UpdatePremiumEvent
              targetID={targetID}
              setShowUpdateForm={setShowUpdateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}

          {showDeleteSection && (
            <DeleteItem
              api={ALL_EVENTS_API}
              targetID={targetID}
              employee={employee}
              setShowModal={setShowModal}
              setShowDeleteSection={setShowDeleteSection}
              hasName
              triggerFetch={triggerFetch}
            />
          )}
        </Modal>
      )}

      {showModal && showViewSection && (
        <Modal
          view
          handleClose={handleClose}
          modalHeading={showViewSection && `View Event Item`}
        >
          <ViewEvent targetID={targetID} />
        </Modal>
      )}
    </>
  );
}

export default PremiumEvents;
