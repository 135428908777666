import React from 'react';
import ReactDOM from 'react-dom/client';
// import { store } from './Redux/store'
// import { Provider } from 'react-redux'

import './index.css';

import App from './Components/App';
import { AppContextProvider } from './AppContext/AppContext';
import { Tooltip } from 'react-tooltip';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <AppContextProvider>
    <App />
    <Tooltip id="my-tooltip" />
  </AppContextProvider>
);

