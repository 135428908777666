import { RiFacebookBoxFill, RiLink, RiSettings2Fill } from "react-icons/ri";

import ScreenHolder from "../../Partials/Layouts/ScreenHolder/ScreenHolder";
import NavCardList from "../../Partials/Layouts/NavCardList/NavCardList";
import NavCard from "../../Partials/Layouts/NavCardList/NavCard/NavCard";
import { useContext, useState } from "react";
import AppContext from "../../../AppContext/AppContext";
import { BsDownload } from "react-icons/bs";
import Modal from "../../Partials/Elements/Modal/Modal";
import DownloadDataModal from "./DownloadData/DownloadDataModal";

function GeneralSettings() {
  const { employee } = useContext(AppContext);

  const [download, setDownload] = useState(false);
  return (
    <>
      <ScreenHolder>
        <NavCardList numOfCards={"seven"}>
          <NavCard cardName={"Social Links"} navCardLink="/social_links">
            <RiFacebookBoxFill />
          </NavCard>
          {/* <NavCard cardName={'Testimonials'} navCardLink='/testimonials' ><RiChatQuoteFill /></NavCard> */}
          <NavCard cardName={"App Settings"} navCardLink="/app_settings">
            <RiSettings2Fill />
          </NavCard>
          <NavCard cardName={"Useful Links"} navCardLink="/usefulLinks">
            <RiLink />
          </NavCard>

          {(employee.level === "admin" || employee.level === "superAdmin") && (
            <button
              onClick={() => setDownload(true)}
              className="nav_card"
              style={{
                display: "flex",
                flexDirection: "column",
                gap : "1rem",
                justifyContent: "center",
                alignItems: "center",
                color: "#fcfc62",
                cursor: "pointer",
                padding: "1rem",
                width: "100%",
                fontSize: "1.5rem",
              }}
            >

              <BsDownload /> Download Data
            </button>
          )}
        </NavCardList>
      </ScreenHolder>

      {download && (
        <Modal 
          handleClose={() => setDownload(false)}
          modalHeading={"Download Data"}
        >
          <DownloadDataModal setDownload={setDownload} />
        </Modal>
      )}
    </>
  );
}

export default GeneralSettings;
