import { useState } from "react";
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance";
import Form from "../../../../Partials/Layouts/Forms/Form";
import ShortTextInput from "../../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import FormSubmitButton from "../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import { USEFUL_LINKS_API } from "../../../../../Utilities/APIs/APIs";

function CreateUsefulLink({
  setShowCreateForm,
  setShowModal,
  triggerFetch,
  setFilter,
}) {
  const [title, setTitle] = useState(0);
  const [link, setLink] = useState(0);
  const axiosInstance = useAxiosInstance();

  async function handleSubmit(e) {
    e.preventDefault();
    const itemData = new FormData();
    itemData.append("title", title);
    itemData.append("link", link);

    const response = await axiosInstance.post(USEFUL_LINKS_API, itemData);

    console.log(response);
    if (response.data) {
      setShowCreateForm(false);
      setShowModal(false);
      triggerFetch();
      setFilter("true");
    }
  }

  return (
    <Form onSubmit={handleSubmit} hasImage>
      <ShortTextInput
        fieldId="2"
        state={title}
        setState={setTitle}
        placeholder="title"
      />
      <ShortTextInput
        fieldId="3"
        state={link}
        setState={setLink}
        placeholder="link"
      />

      <FormSubmitButton text="Create Link" />
    </Form>
  );
}

export default CreateUsefulLink;
