import { useEffect, useState } from "react";
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance";
import { USEFUL_LINKS_API } from "../../../../../Utilities/APIs/APIs";
import Form from "../../../../Partials/Layouts/Forms/Form";
import ShortTextInput from "../../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import FormSubmitButton from "../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";

function UpdateUsefulLink({
  targetID,
  triggerFetch,
  setShowUpdateForm,
  setShowModal,
}) {
  const [title, setTitle] = useState("");
  const [link, setLink] = useState("");
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetProductInfo() {
      const { data } = await axiosInstance.get(
        USEFUL_LINKS_API + "singleUsefulLink/" + targetID
      );
      setTitle(data.title);
      setLink(data.link);
    }
    fetchAndSetProductInfo();
  }, [targetID, axiosInstance]);

  async function handleSubmit(e) {
    e.preventDefault();

    const itemData = new FormData();

    itemData.append("title", title);
    itemData.append("link", link);

    const response = await axiosInstance.patch(
      USEFUL_LINKS_API + "singleUsefulLink/" + targetID,
      itemData
    );

    if (response) {
      setShowUpdateForm(false);
      setShowModal(false);
      triggerFetch();
    }
  }

  return (
    <Form onSubmit={handleSubmit} hasImage>
      <ShortTextInput
        label={`Title`}
        value={title}
        placeholder={`Enter Title`}
        setState={setTitle}
      />
      <ShortTextInput
        label={`Link`}
        value={link}
        placeholder={`Enter Link`}
        setState={setLink}
      />

      <FormSubmitButton text="Update Useful Link" />
    </Form>
  );
}

export default UpdateUsefulLink;
