import { useContext, useEffect, useState } from "react";

import { MANAGE_CUSTOMER } from "../../../../../Utilities/APIs/APIs";
import Modal from "../../../../Partials/Elements/Modal/Modal";
import CRUDButton from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/CRUDButton";
import ViewButton from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton";
import CRUDTable from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable";
import ShortTextCell from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import CRUDTableHeader from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow";
import ViewCustomer from "./ViewCustomer";
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance";
import AppContext from "../../../../../AppContext/AppContext";
import { HiBadgeCheck } from "react-icons/hi";
import CustomerApproveItem from "./CustomerApproveItem";
import "./customers.css";

function Customers() {
  const [customers, setCustomers] = useState(null);
  const [showApprove, setShowApprove] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [showViewSection, setShowViewSection] = useState(false);

  const { employee } = useContext(AppContext);

  const axiosInstance = useAxiosInstance();
  useEffect(() => {
    async function fetchAndSetCustomers() {
      const { data } = await axiosInstance.get(
        MANAGE_CUSTOMER + "getAllCustomers"
      );
      setCustomers(data);

      // console.log(data);
    }
    fetchAndSetCustomers();
  }, [axiosInstance, toggleFetch]);

  function handleClose() {
    setShowModal(false);
    setShowViewSection(false);
  }

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }

  return (
    <>
      <div className="crud_holder">
        <div className="body_header">
          <h1 className="heading">{`Members (${customers?.length} in total)`}</h1>
        </div>
        <CRUDTable>
          <CRUDTableHeader>
            <CRUDth th="No" />
            <CRUDth th="Member Name" />
            {/* <CRUDth th="Image" /> */}
            <CRUDth th="Email" />
            <CRUDth th="Status" />
            <CRUDth th="Actions" />
          </CRUDTableHeader>
          <tbody>
            {customers?.map((customer, index) => (
              <CRUDTableRow key={customer._id}>
                <ShortTextCell text={index + 1} />
                <ShortTextCell text={customer.fullName} />

                <ShortTextCell text={customer.emailAddress} />
                <ShortTextCell text={customer.isApproved} />
                <td className="action_button_cell new">
                  <ViewButton
                    tolTipContent={"View"}
                    setShowModal={setShowModal}
                    setShowViewSection={setShowViewSection}
                    targetID={customer._id}
                    setTargetID={setTargetID}
                  />

                  <div className="action_button_cell">
                    <div className="action_button_cell">
                      {/* Check if the customer is approved */}
                      {customer.isApproved === "pending" && ( // Render nothing if approved
                        // Render the CRUDButton if not approved
                        <CRUDButton
                          tolTipContent={"Approve"}
                          handleClick={() => {
                            setShowModal(true);
                            setShowApprove(true);
                            setTargetID(customer._id);
                          }}
                        >
                          <HiBadgeCheck />
                        </CRUDButton>
                      )}
                    </div>
                  </div>
                </td>
              </CRUDTableRow>
            ))}
          </tbody>
        </CRUDTable>
      </div>
      {showModal && (
        <Modal
          handleClose={handleClose}
          modalHeading={showViewSection && `View Member Details`}
        >
          {showApprove && (
            <CustomerApproveItem
              // api={EVENTS_API + "/ticket/"}
              targetID={targetID}
              employee={employee}
              setShowModal={setShowModal}
              setShowDeleteSection={setShowDeleteSection}
              hasName
              triggerFetch={triggerFetch}
            />
          )}
          {showViewSection && (
            <ViewCustomer targetID={targetID} employee={employee} />
          )}
        </Modal>
      )}
    </>
  );
}

export default Customers;
