import { useEffect, useState } from "react";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import { ALL_EVENTS_API, EVENTS_API } from "../../../../Utilities/APIs/APIs";
import Image from "../../../Partials/Elements/Image/Image";

function ViewEvent({ targetID }) {
  const [eventInfo, setEventIngo] = useState(null);
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndsetGalleryIngo() {
      const { data } = await axiosInstance.get(
        ALL_EVENTS_API  + targetID
      );
      setEventIngo(data);
    }
    fetchAndsetGalleryIngo();
  }, [targetID, axiosInstance]);

  return (
    <div className="crud_view_content">
      {eventInfo && (
        <>
          <h1>Title</h1>
          <p>{eventInfo?.title}</p>

          <h1>Description</h1>
          <p>{eventInfo?.shortDescription}</p>
          <h1>Long Description</h1>
          <p>{eventInfo?.longdescription}</p>
          <h1>Card Image</h1>
          <Image imgLink={eventInfo?.image} imgAlt={"Card Front Image"} />
          

          <h1>Category</h1>
          <p>{eventInfo?.category}</p>
          <h1>Location</h1>
          <p>{eventInfo?.location}</p>
          <h1>Google Map Link</h1>
          <p>{eventInfo?.googleMapLink}</p>
          <h1>Date String</h1>
          <p>{eventInfo?.dateString}</p>
          <h1>participationTitle</h1>
          <p>{eventInfo?.participationTitle}</p>
          <h1>Bank Name</h1>
          <p>{eventInfo?.bankName}</p>
          <h1>Account Holder Name</h1>
          <p>{eventInfo?.accountHolderName}</p>
          <h1>Account Number</h1>
          <p>{eventInfo?.accountNumber}</p>

          
        </>
      )}
    </div>
  );
}

export default ViewEvent;
